@font-face {
  font-family: "Selawik";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/selawik/selawkl.woff") format("woff");
}

/*@font-face {*/
/*    font-family: 'Selawik';*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    src: url('../assets/fonts/selawik/selawk.woff') format('woff')*/
/*}*/

/*@font-face {*/
/*    font-family: 'Selawik';*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    src: url('../assets/fonts/selawik/selawksb.woff') format('woff')*/
/*}*/

/*@font-face {*/
/*    font-family: 'Selawik';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    src: url('../assets/fonts/selawik/selawkb.woff') format('woff');*/
/*}*/

/*!* montserrat-600 - latin_cyrillic *!*/
/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    src: url('../assets/fonts/montserrat-v14-latin_cyrillic-600.eot'); !* IE9 Compat Modes *!*/
/*    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-600.woff2') format('woff2'), !* Super Modern Browsers *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-600.woff') format('woff'), !* Modern Browsers *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-600.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-600.svg#Montserrat') format('svg'); !* Legacy iOS *!*/
/*}*/
/*!* montserrat-800 - latin_cyrillic *!*/
/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    font-style: normal;*/
/*    font-weight: 800;*/
/*    src: url('../assets/fonts/montserrat-v14-latin_cyrillic-800.eot'); !* IE9 Compat Modes *!*/
/*    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-800.woff2') format('woff2'), !* Super Modern Browsers *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-800.woff') format('woff'), !* Modern Browsers *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-800.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-800.svg#Montserrat') format('svg'); !* Legacy iOS *!*/
/*}*/
/*!* montserrat-700 - latin_cyrillic *!*/
/*@font-face {*/
/*    font-family: 'Montserrat';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    src: url('../assets/fonts/montserrat-v14-latin_cyrillic-700.eot'); !* IE9 Compat Modes *!*/
/*    src: local('Montserrat Bold'), local('Montserrat-Bold'),*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-700.woff2') format('woff2'), !* Super Modern Browsers *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-700.woff') format('woff'), !* Modern Browsers *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-700.ttf') format('truetype'), !* Safari, Android, iOS *!*/
/*    url('../assets/fonts/montserrat-v14-latin_cyrillic-700.svg#Montserrat') format('svg'); !* Legacy iOS *!*/
/*}*/

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Segoe UI.ttf"); /* IE9 Compat Modes */
  src: local("Segoe UI"), local("Segoe UI"),
    url("../assets/fonts/SegoeUI.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/SegoeUI.woff")
      format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Segoe UI.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Segoe UI Bold.ttf"); /* IE9 Compat Modes */
  src: local("Segoe UI Bold"), local("Segoe UI Bold"),
    url("../assets/fonts/SegoeUI-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/SegoeUI-Bold.woff")
      format("woff"),
    /* Modern Browsers */ url("../assets/fonts/Segoe UI Bold.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Segoe UI", sans-serif !important;
}

body #root {
  position: relative;
  overflow-x: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-dropdown {
  z-index: 1500000 !important;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1140px !important;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
