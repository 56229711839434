/*!
 * Icon Font icon8
 * Made with love by Icons8 [ https://icons8.com/ ] using webfont [ https://github.com/itgalaxy/webfont ]
 *
 * Contacts:
 *    [ https://icons8.com/contact ]
 *
 * Follow Icon8 on
 *    Twitter [ https://twitter.com/icons_8 ]
 *    Facebook [ https://www.facebook.com/Icons8 ]
 *    Google+ [ https://plus.google.com/+Icons8 ]
 *    GitHub [ https://github.com/icons8 ]
 */

@font-face {
  font-family: "icon8";
  src: url("../fonts/icon8.eot");
  src: url("../fonts/icon8.eot?#iefix") format("embedded-opentype"),
  url("../fonts/icon8.woff2") format("woff2"),
  url("../fonts/icon8.woff") format("woff"),
  url("../fonts/icon8.ttf") format("truetype"),
  url("../fonts/icon8.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icon8";
    src: url("../fonts/icon8.svg#icon8") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8-guarantee:before,
.icons8-info:before,
.icons8-handshake:before,
.icons8-document:before,
.icons8-map:before,
.icons8-phone:before,
.icons8-right:before,
.icons8-sort-down:before,
.icons8-circled-play:before,
.icons8-plus-math:before,
.icons8-add:before,
.icons8-down-arrow:before,
.icons8-up:before,
.icons8-download:before,
.icons8-external-link:before,
.icons8-left:before,
.icons8-new-post:before,
.icons8-location:before,
.icons8-user:before,
.icons8-user-account:before,
.icons8-male-user:before,
.icons8-settings:before,
.icons8-menu:before,
.icons8-squared-menu:before,
.icons8-list:before,
.icons8-bulleted-list:before,
.icons8-computer-support:before,
.icons8-laptop:before,
.icons8-laptop-settings:before,
.icons8-cloud:before,
.icons8-cloud-checked:before,
.icons8-sms:before,
.icons8-video-message:before,
.icons8-people:before,
.icons8-person-at-home:before,
.icons8-user-account-2:before,
.icons8-phone-2:before,
.icons8-iphone:before,
.icons8-code:before,
.icons8-source-code:before,
.icons8-paper-money:before,
.icons8-web:before,
.icons8-design:before,
.icons8-wrench:before,
.icons8-star:before,
.icons8-launch:before,
.icons8,
[data-icons8]:before {
  display: inline-block;
  font-family: "icon8";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


.icons8-guarantee:before {content: "\f100";}
.icons8-info:before {content: "\f101";}
.icons8-handshake:before {content: "\f102";}
.icons8-document:before {content: "\f103";}
.icons8-map:before {content: "\f104";}
.icons8-phone:before {content: "\f105";}
.icons8-right:before {content: "\f106";}
.icons8-sort-down:before {content: "\f107";}
.icons8-circled-play:before {content: "\f108";}
.icons8-plus-math:before {content: "\f109";}
.icons8-add:before {content: "\f10a";}
.icons8-down-arrow:before {content: "\f10b";}
.icons8-up:before {content: "\f10c";}
.icons8-download:before {content: "\f10d";}
.icons8-external-link:before {content: "\f10e";}
.icons8-left:before {content: "\f10f";}
.icons8-new-post:before {content: "\f110";}
.icons8-location:before {content: "\f111";}
.icons8-user:before {content: "\f112";}
.icons8-user-account:before {content: "\f113";}
.icons8-male-user:before {content: "\f114";}
.icons8-settings:before {content: "\f115";}
.icons8-menu:before {content: "\f116";}
.icons8-squared-menu:before {content: "\f117";}
.icons8-list:before {content: "\f118";}
.icons8-bulleted-list:before {content: "\f119";}
.icons8-computer-support:before {content: "\f11a";}
.icons8-laptop:before {content: "\f11b";}
.icons8-laptop-settings:before {content: "\f11c";}
.icons8-cloud:before {content: "\f11d";}
.icons8-cloud-checked:before {content: "\f11e";}
.icons8-sms:before {content: "\f11f";}
.icons8-video-message:before {content: "\f120";}
.icons8-people:before {content: "\f121";}
.icons8-person-at-home:before {content: "\f122";}
.icons8-user-account-2:before {content: "\f123";}
.icons8-phone-2:before {content: "\f124";}
.icons8-iphone:before {content: "\f125";}
.icons8-code:before {content: "\f126";}
.icons8-source-code:before {content: "\f127";}
.icons8-paper-money:before {content: "\f128";}
.icons8-web:before {content: "\f129";}
.icons8-design:before {content: "\f12a";}
.icons8-wrench:before {content: "\f12b";}
.icons8-star:before {content: "\f12c";}
.icons8-launch:before {content: "\f12d";}
